import { formCtrl } from './controllers/formCtrl'

import angular from 'angular'
import '@/shared'
import { makeCoreInterop } from '@/shared/coreInterop'
import {
  autoItState,
  companyGroupState,
  controlpanelDepreceiationModelState,
  controlpanelDepreciationMatrixState,
  controlpanelFinancingPartnerState,
  controlpanelProductState,
  makeFormState,
  makeIndexState,
  makeStateConfig,
  recalculationState,
  reportSummaryState,
} from '@/shared/config/states'
import formTemplate from '@/nordic/templates/form.html'
import formsCompanyGroupTemplate from '@/shared/templates/forms/companyGroup.html'

const app = angular.module('carleasing', ['carleasing.shared'])

app.value(
  '$interop',
  makeCoreInterop({
    module: 'nordic',
    prettyModuleName: 'Nordic Leasing',
    features: [
      'article.icon',
      'user.mobile',
      'asg',
      'roadsideAssistance',
      'germanComparisonPrice',
      'commonConfig.nummerpladerOmregistrering',
      'commonConfig.nummerpladerAvance',
      'commonConfig.web',
      'commonConfig.deliveryDateMonthOffset',
      'propellantPrice',
      'commonConfig.beskaffenhedStandStandard',
      'commonConfig.overkoerselKmPrisMin',
      'commonConfig.commonConfigNummerplader',
      'commonConfig.leasinggiverBetalerNummerpladerDefault',
      'commonConfig.configurableServiceLocation',
      'company.tilbudsmodtagelsesunderskrift',
      'company.godkendtKautionist',
      'company.godkendtAnviser',
      'company.tilladAlternativLeverandoer',
      'company.enableService',
      'company.defaultAnviser',
      'company.defaultProductId',
      'companyGroup.dokumentgebyrOpkreavningsmetodeDefault',
      'companyGroup.deklarationForsikringOpkraevesSeparatStandard',
      'companyGroup.dokumentgebyrPct',
      'companyGroup.rentesatsRegFaktorNeg',
      'companyGroup.rentesatsRegFaktorPos',
      'companyGroup.licensePlates',
      'companyGroup.pledgeDeclarationFee',
      'companyGroup.vehicleImportCostProductGroup',
      'mailTemplate.approvalLeasing',
      'mailTemplate.uploadNotification',
      'mailTemplate.companyPledgeWarning',
      'commonConfig.paymentDays',
      'companyGroup.administrationFeeVisible',
      'roadsideAssistance',
      'commonConfig.web',
      'carGarantie',
      'hasFinancing',
      'hasAutoApproval',
      'hasLegacyCustomerLink',
      'hasExternal',
      'companyGroup.companyGroupProductPrice',
      'commonConfig.nummerpladerAvanceOmregistrering',
      'rejectComment.canIncludeComment',
    ],
    controlPanelStates: [
      'root.controlpanel.company',
      'root.controlpanel.companyGroup',
      'root.controlpanel.commonConfig',
      'root.controlpanel.prt',
      'root.controlpanel.insuranceCompany',
      'root.controlpanel.insuranceCosts',
      'root.controlpanel.gpsProvider',
      'root.controlpanel.article',
      'root.controlpanel.news',
      'root.controlpanel.mailTemplates',
      'root.controlpanel.penneo',
      'root.controlpanel.financingPartner',
      'root.controlpanel.product',
      'root.controlpanel.depreciationModel',
      'root.controlpanel.depreciationMatrix',
    ],
    terms: {
      downPayment: 'Første ydelse',
      administrationFee: 'Administrationsgebyr',
    },
  }),
)

app.config(
  makeStateConfig([
    controlpanelFinancingPartnerState,
    controlpanelProductState,
    controlpanelDepreciationMatrixState,
    controlpanelDepreceiationModelState,
    recalculationState,
    autoItState,
    reportSummaryState,
    makeFormState(formTemplate),
    makeIndexState(null),
    companyGroupState(formsCompanyGroupTemplate),
  ]),
)

app.controller('formCtrl', formCtrl)
